

#backToTopButton{
    position: sticky;
    height: 40px;
    width: 40px;
    align-self: self-end;
    right: 5vw;
    bottom: 205px;
    border-radius: 20px;
    border-width: 0px;   
    background:url("../../resources/arrow.svg") no-repeat 45%/30%;
    background-color:rgb(77, 136, 255);
    transform: rotate(90deg);
}

#backToTopButton:hover{
   
    box-shadow: 0px 0px 20px 5px rgba(77, 136, 255, 1);
    transform: scale(1.1) rotate(90deg);
    cursor: pointer;
 
}

#backToTopButton:active{
   
    box-shadow: 0px 0px 20px 5px rgba(77, 136, 255, 1);
    transform: scale(1.00);
    transform: rotate(90deg);

 
}